<template>
  <div>
    <div class="item" v-for="(item,index) in dataList" :key="index">
      <div class="container">
        <div class="row">
          <div
            data-aos="fade-up"
            data-aos-offset="0"
            data-aos-delay="50"
            data-aos-duration="800"
            :class="{'col-12 col-sm':true,'order-sm-8':index%2==0}"
            v-if="item.image"
          >
            <img :src="item.image" />
          </div>
          <div
            data-aos="fade-up"
            data-aos-offset="0"
            data-aos-delay="50"
            data-aos-duration="800"
            class="col-12 col-sm text"
            v-if="item.title&&item.subTitle"
          >
            <h2 :style="{fontSize:item.titleFontSize||'40px',color:item.titleFontColor||'#0F1215'}">{{item.title}}</h2>
            <p :style="{fontSize:item.subTitleFontSize||'18px',color:item.subTitleFontColor||'#666'}">{{item.subTitle}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  watch: {},
  methods: {},
  created() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
  .item {
    min-height: 1px !important;
    img {
      max-height: 280px !important;
    }
    h2 {
      font-size: 28px !important;
    }
    p {
      font-size: 16px !important;
    }
  }
}
.item {
  text-align: center;
  min-height: 500px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 450px;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    h2 {
      font-weight: bold;
      line-height: 180%;
    }
    p {
      line-height: 140%;
    }
  }
}
.item:nth-child(odd) {
  background: #f8f8f8;
}
</style>