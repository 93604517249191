<template>
  <div>
    <div class="app-top">
      <swiper-list :dataList="swiperList" :height="500" :interval="4000"></swiper-list>
    </div>
    <intro-list :dataList="introList"></intro-list>
    <div id="apply" class="agent" data-aos="fade-up" data-aos-offset="0" data-aos-delay="50" data-aos-duration="800">
      <div class="title">
        <h3>
          <b style="font-size:30px;">简单客</b> · 合伙人申请表
        </h3>
        <p>请您如实填写信息，提交合作意向后，我们将在72小时内与您取得联系</p>
      </div>
      <div class="form-wrap">
        <b-form>
          <b-form-group label="您的姓名：" :inline="true">
            <b-form-input v-model="form.name" type="text" required placeholder="您的姓名"></b-form-input>
          </b-form-group>
          <b-form-group label="联系电话：">
            <b-form-input v-model="form.phone" required placeholder="联系电话"></b-form-input>
          </b-form-group>

          <b-form-group label="合作意向：">
            <b-form-textarea v-model="form.purpose" placeholder="（选填）请简单描述合作意向"></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <b-button variant="success" size="lg" @click="onSubmit">提交</b-button>
          </b-form-group>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/agent'
import swiperList from '@/components/swiperList'
import introList from '@/components/introList'

export default {
  name: 'agentIndex',
  components: {
    swiperList,
    introList
  },
  data() {
    return {
      dataList: [],
      form: {
        name: '',
        phone: '',
        purpose: ''
      },
      swiperList: [
        {
          image: '',
          title: '合作共赢 共创财富',
          subTitle: '面向全国诚招合伙人，期待志同道合的你！',
          backgroundColor: 'linear-gradient(rgba(100,100,100,0.5), rgba(100,100,100,0.5))',
          backgroundImage: require('@/assets/images/bg/bg-9.jpg'),
          buttons: [
            {
              color: 'success',
              text: '加入代理',
              href: '#apply'
            },
            {
              color: 'danger',
              text: '代理登录',
              href: 'http://agent.jiandanke.cn'
            }
          ]
        }
      ],
      introList: [
        {
          image: require('@/assets/images/agent1.png'),
          title: '信息化时代，蕴含广阔商机',
          subTitle:
            '随着中小企业和个体店铺对信息化管理的需求日益剧增，特别是对会员、客户、仓库的管理需求越来越旺盛，让管理软件市场越来越庞大。 面对如此巨大的市场蛋糕，如何能从中获取可观的利润，是每一个软件厂商以及代理商不得不思考的问题。中国有8000万线下门店急需进入数字化时代，代替手工账本，庞大的市场需求，期待与志同道合的合伙人共享这一巨大利润蛋糕。'
        },
        {
          image: require('@/assets/images/agent2.png'),
          title: '',
          subTitle: ''
        },
        {
          image: require('@/assets/images/agent3.png'),
          title: '',
          subTitle: ''
        },
        {
          image: require('@/assets/images/agent4.png'),
          title: '',
          subTitle: ''
        }
      ]
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {
    onSubmit() {
      console.log('我要提交')
      API.apply(this.form).then(res => {
        console.log(res)
        if (res.status == 0) {
          this.form.name = ''
          this.form.phone = ''
          this.form.purpose = ''
        }
        alert(res.message)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
  .swimlane-container {
    h2 {
      font-size: 28px !important;
    }
    p {
      font-size: 22px !important;
    }
  }
}

.app-top {
  margin-top: 58px;
}
.agent {
  padding-top: 58px;
  .title {
    text-align: center;
    h3 {
      color: #30c17b;
      margin-top: 10px;
      font-weight: 600;
    }
    p {
      color: #999;
    }
  }
  .form-wrap {
    width: 90%;
    max-width: 600px;
    margin: 30px auto;
    padding: 10px;
    button {
      margin-top: 10px;
      width: 100%;
    }

    // display: flex;
    // justify-content: center;
    // .form-group {
    //   display: flex;
    //   align-items: center;
    //   .d-block {
    //     width: 100px;
    //   }
    //   div {
    //     flex: 1;
    //   }
    // }
  }
}
</style>