import { render, staticRenderFns } from "./introList.vue?vue&type=template&id=939363cc&scoped=true&"
import script from "./introList.vue?vue&type=script&lang=js&"
export * from "./introList.vue?vue&type=script&lang=js&"
import style0 from "./introList.vue?vue&type=style&index=0&id=939363cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "939363cc",
  null
  
)

export default component.exports